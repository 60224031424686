<template>
    <div class="mt-5">
        <ChecklistInicialGrid
            ref="checklistInicialGrid"
            @onCreateBeforeOpen="onCreateBeforeOpen"
            @onLoadDialog="onLoadDialog"
        />

        <ChecklistAmbienteGrid
            ref="checklistAmbienteGrid"
            @onCreateBeforeOpen="onCreateBeforeOpen"
            @onLoadDialog="onLoadDialog"
            class="mt-3"
        />

        <ChecklistIdentificacao
            ref="checklistIdentificacao"
            :etapa="'LEVANTAMENTO_PERIGO_RISCO'"
        />

        <ResponsavelInventarioRisco
            ref="responsavelInventarioRisco"
            :etapa="'LEVANTAMENTO_PERIGO_RISCO'"
        />

        <div class="flex justify-content-end">
            <Button
                class="btn-concluir bg-primary-reverse"
                label="Voltar"
                @click="voltarParaListagem"
            />

            <Button
                :loading="loadingConcluir"
                class="btn-concluir bg-green-600"
                label="Concluir etapa"
                @click="concluir"
            />
        </div>

        <DialogQuestionario
            ref="DialogQuestionario"
            :title="dialogTitle"
            :service="service"
            @onAfterSave="onAfterSave"
        />
    </div>
</template>

<script>
import OrigemAplicacaoRespostaEnum from '../../../../enums/OrigemAplicacaoRespostaEnum';
import ChecklistInicialGrid from '../checklist/ChecklistInicialGrid.vue';
import ChecklistAmbienteGrid from '../checklist/ChecklistAmbienteGrid.vue';
import ChecklistIdentificacao from '../checklist/ChecklistIdentificacao.vue'
import DialogQuestionario from '../../components/checklist/DialogQuestionario.vue';
import AplicacaoRespostaService from '../../services/AplicacaoRespostaService';
import { showError, showSuccess } from '../../../../utils/Toast';
import InventarioRiscoService from '../../services/InventarioRiscoService';
import eventBus from '../../../../event-bus';
import ResponsavelInventarioRisco from '../responsavel/ResponsavelInventarioRisco.vue';
import moment from 'moment';

export default {
    emits: ['onBack'],
    components: {
        ChecklistInicialGrid,
        ChecklistAmbienteGrid,
        ChecklistIdentificacao,
        DialogQuestionario,
        ResponsavelInventarioRisco
    },
    data() {
        return {
            service: null,
            inventarioRiscoService: null,
            dialogTitle: '',
            loadingConcluir: false,
            etapa: 'LEVANTAMENTO_PERIGO_RISCO'
        }
    },
    created() {
        this.service = new AplicacaoRespostaService('aplicacao-resposta');
        this.inventarioRiscoService = new InventarioRiscoService();
    },
    methods: {
        onAfterSave(origem) {
            if (origem == OrigemAplicacaoRespostaEnum.INICIAL) this.$refs.checklistInicialGrid.load();
            if (origem == OrigemAplicacaoRespostaEnum.AMBIENTE) this.$refs.checklistAmbienteGrid.load();
        },
        async onCreateBeforeOpen(data, origem) {
            try {
                this.iniciarLoadingAcaoQuestionarioPorOrigem(data, origem);
                await this.salvarNaoIniciado(data.id, origem);
                showSuccess(this.$toast, 'Registro iniciado.');
                this.onLoadDialog(data, origem);
            } catch (error) {
                this.finalizarLoadingAcaoQuestionarioPorOrigem(origem);
                showError(this.$toast, error);
            }
        },
        iniciarLoadingAcaoQuestionarioPorOrigem(data, origem) {
            if (origem == OrigemAplicacaoRespostaEnum.AMBIENTE) {
                this.$refs.checklistAmbienteGrid.iniciarLoadingAcao(data);
                return;
            }

            this.$refs.checklistInicialGrid.iniciarLoadingAcao(data);
        },
        async onLoadDialog(data, origem) {
            this.iniciarLoadingAcaoQuestionarioPorOrigem(data, origem);
            this.dialogTitle = this.getTitle(origem);
            await this.$refs.DialogQuestionario.load({ origemId: data.id, origem, unidade: data.unidade, etapa: this.etapa });
            this.finalizarLoadingAcaoQuestionarioPorOrigem(origem);
        },
        finalizarLoadingAcaoQuestionarioPorOrigem(origem) {
            if (origem == OrigemAplicacaoRespostaEnum.AMBIENTE) {
                this.$refs.checklistAmbienteGrid.finalizarLoadingAcao();
                return;
            } 
            
            this.$refs.checklistInicialGrid.finalizarLoadingAcao();
        },
        getTitle(origem) {
            if (origem == OrigemAplicacaoRespostaEnum.INICIAL) return 'CHECKLIST INICIAL';
            if (origem == OrigemAplicacaoRespostaEnum.AMBIENTE) return 'CHECKLIST AMBIENTE';
        },
        async salvarNaoIniciado(origemId, origem) {
            const entity = {
                origemId,
                origem,
                inventarioRiscoId: this.$route.params.id,
                etapa: this.etapa
            }
            await this.service.startQuestionario(entity);
        },
        async concluir() {
            try {
                this.loadingConcluir = true;
                const dataConclusao = this.$refs.responsavelInventarioRisco?.form?.dataConclusao;
                const dataConclusaoFormatada = moment.tz(dataConclusao, 'YYYY-MM-DD', 'America/Sao_Paulo');
                const resposta = await this.inventarioRiscoService.concluirEtapa(this.$route.params.id, dataConclusaoFormatada);
                this.loadingConcluir = false;
                showSuccess(this.$toast, 'Etapa concluída com sucesso!');
                this.$router.push(`/security/inventario-risco2/aplicacao/${resposta.data.id}/${resposta.data.etapa}`);
                eventBus.emit('onReloadSteps');
            } catch (error) {
                this.loadingConcluir = false;
                showError(this.$toast, error);
            }
        },
        voltarParaListagem() {
            this.$router.push('/security/inventario-risco2');
        }
    }
}
</script>

<style>

</style>